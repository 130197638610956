
import { defineComponent } from 'vue'
import Main from '@/templates/Main.vue'
import HistoryOfNaughtyMeterSection from '@/components/sections/dashboard/HistoryOfNaughtyMeterSection.vue'

export default defineComponent({
  name: 'HistoryOfNaughtyMeter',
  components: {
    Main,
    HistoryOfNaughtyMeterSection
  }
})
