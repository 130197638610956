
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HistoryOfNaughtyMeterSection',
  data () {
    return {
      paymentSuccessful: false,
      marksData: {}
    }
  },
  components: {
  },
  mounted () {
    this.getMarks()
  },
  methods: {
    getMarks () {
      this.$store.dispatch('getPayments/getMarks').then((res) => {
        this.marksData = res.data.data
      },
      (error) => {
        this.message = error.response.data.errors
      })
    }
  }
})
